@import '../../Styles/variables.scss';

.k5-navbar {
  align-items: baseline;
  background-color: transparent;
  border-bottom: 7px solid black;
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  position: sticky;
  top: 0px;
  transition: all ease 0.3s;

  &.-scrolled {
    background-color: rgba(255, 255 , 255, 0.5);
    backdrop-filter: blur(10px);
  }

  img {
    height: 50px;
  }

  .nav-options {
    @include media-breakpoint-up (md) {
      display: flex;
    }

    display: none;

    .nav-link {
      color: $text-color;
      font-weight: bold;
      margin-left: 50px;
      padding: 5px;
      text-decoration: none;
      transition: all ease 0.6s;

      &:hover {
        background-color: $black-color;
        color: $white-color;
      }
    }
  }
}