//MARGINS//
.m-xs {
  margin: 10px;
}

.m-s {
  margin: 20px;
}

.m-m {
  margin: 40px;
}

.m-l {
  margin: 50px;
}

.m-xl {
  margin: 100px;
}

//MARGIN TOP//

.mt-xs {
  margin-top: 10px;
}

.mt-s {
  margin-top: 20px;
}

.mt-m {
  margin-top: 40px;
}

.mt-l {
  margin-top: 50px;
}

.mt-xl {
  margin-top: 100px;
}

.mt-xxl {
  margin-top: 150px;
}

//MARGIN BOTTOM//

.mb-xs {
  margin-bottom: 10px;
}

.mb-s {
  margin-bottom: 20px;
}

.mb-m {
  margin-bottom: 40px;
}

.mb-l {
  margin-bottom: 50px;
}

.mb-xl {
  margin-bottom: 100px;
}

.mr-xs {
  margin-right: 10px;
}

//MARGIN Y

.my-xs {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-s {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-m {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-l {
  margin-top: 50px;
  margin-bottom: 50px;
}

.my-xl {
  margin-top: 100px;
  margin-bottom: 100px;
}

//MARGIN X//

.mx-xs {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-s {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-m {
  margin-left: 40px;
  margin-right: 40px;
}

.mx-l {
  margin-left: 50px;
  margin-right: 50px;
}

.mx-xl {
  margin-left: 100px;
  margin-right: 1000px;
}

//PADDINGS//
.p-xs {
  padding: 10px;
}

.p-s {
  padding: 20px;
}

.p-m {
  padding: 40px;
}

.p-l {
  padding: 50px;
}

.p-xl {
  padding: 100px;
}

//PADDING Y

.py-xs {
  padding-top:  10px;
  padding-bottom: 10px;
}

.py-s {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-m {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-l {
  padding-top: 50px;
  padding-bottom: 50px;
}

.py-xl {
  padding-top: 100px;
  padding-bottom: 100px;
}

//PADDING X//

.px-xxs {
  padding-left: 6px;
  padding-right: 6px;
}

.px-xs {
  padding-left: 10px;
  padding-right: 10px;
}

.px-s {
  padding-left: 20px;
  padding-right: 20px;
}

.px-m {
  padding-left: 40px;
  padding-right: 40px;
}

.px-l {
  padding-left: 50px;
  padding-right: 50px;
}

.px-xl {
  padding-left: 100px;
  padding-right: 100px;
}


.f-18 {
  font-size: 18px;
}
