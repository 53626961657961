.collapse-card {
  .section {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    transition: all ease 0.4s;
    width: 100%;

    .text {
      color: $red-color;
      transition: all ease 0.3s;
      white-space: pre;

      &:hover {
        text-decoration: underline 2px;
      }
    }
  }

  .content-card {
    background-color: $black-color;
    display: none;
    height: 0px !important;
    transition: all ease 0.4s;

    .image-wrap {
      @include media-breakpoint-up (md) {
        aspect-ratio: 1/1.5;
      }

      @include media-breakpoint-up (lg) {
        aspect-ratio: 1/1;
      }

      aspect-ratio: 2/1;
      overflow: hidden;
      width: 100%;
  
      img {
        width: 200%;
      }
    }

    .contnt {
      padding: 50px;
    }
  }

  &.-open {
    .content-card {
      display: block;
      height: auto !important;
    }
  }
}