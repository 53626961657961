// COLOR DEFINITION //
$black-color: #1B1D1F;
$blue-color: #0F58EE;
$blue-dark-color: #043286;
$red-color: #FD2C31;
$yellow-color: #F1D12E;
$white-color: #FFFFFF;

// COLORS USED //
$text-color: $black-color;
