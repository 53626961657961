@import '../../Styles/variables.scss';

.k5-footer {
  align-items: baseline;
  background-image: url(../../Images/Backgrounds/footer.svg);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0px 0px;

  img {
    height: 50px;
    margin: 0px 20px;
  }

  .footer-info {
    border-top: 7px solid black;
    margin-top: 20px;
    padding: 20px 20px 0px ;
    width: 100%;
  }

  .rights {
    background-color: $blue-color;
    color: $white-color;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 20px;
    padding: 8px;
    text-align: center;
    width: 100%;
  }
}