@import './variables.scss';

.front-title {
  @include media-breakpoint-up (lg) {
    font-size: 80px;
  }

  align-items: center;
  aspect-ratio: 1/1;
  background-color: $yellow-color;
  border-radius: 50%;
  display: flex;
  font-size: 50px;
  font-weight: bold;
  margin-top: -60px;
  padding-left: 40px;
}

.bg-home {
  @media (max-width: 573px) {
    background-position-x: -490px;
    background-position-y: -110px;
  }

  background-image: url(../Images/Backgrounds/frontpage.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 0px;
  background-position-y: 0px;
}

.mask {
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
}

.pr-0 {
  @include media-breakpoint-up (md) {
    padding-right: auto;
  }

  padding-right: 0px;
}

.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;

  .service {
    @include media-breakpoint-down (sm) {
      font-size: 20px;
    }

    color: $blue-dark-color;
    font-size: 28px;
    font-weight: bold;
    margin-right: 10px;
    transition: all ease 0.5s;

    &:hover {
      text-decoration: none;
      color: $white-color;
    }
  }
}

.contact-title {
  @include media-breakpoint-down (lg) {
    font-size: 45px;
    line-height: 70px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  color: $black-color;
  font-size: 80px;
  font-weight: bold;
  line-height: 100px;
  margin-bottom: 40px;
}

.p-contact {
  @include media-breakpoint-down (lg) {
    padding: 0px;
  }

  padding: 100px 40px 60px;
}

.contact-img {
  @include media-breakpoint-up (md) {
    display: none;
  }
}

.contact-img-lg {
  @include media-breakpoint-down (md) {
    display: none;
  }
}