body {
  font-family: Montserrat, sans-serif;
}

.title {
  font-size: 40px;
  font-weight: bold;
}

.subtitle {
  font-size: 30px;
  font-weight: bold;
}

.big-text {
  font-size: 20px;
  font-weight: bold;
}

p {
  font-size: 16px;
  margin-bottom: 20px;

  &.-white {
    color: $white-color;
  }

  &.-blue {
    color: $blue-color;
  }

  &.-red {
    color: $red-color;
  }
}

b {
  color: $blue-dark-color;
}

hr {
  height: 5px !important;
  color: $blue-color;
  opacity: 1;
}

.bg-bl {
  background-color: $blue-color;
}